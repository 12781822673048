<template>
  <div>
    <div class="py-5 text-center">
      <img src="./images/icon-check-circle.svg" />
      <div class="mt-3 font-weight-bold">{{ deepGet(preorder, 'config.finish_page.form_title') }}</div>
      <p class="one-line-note">{{ deepGet(preorder, 'config.finish_page.form_desc') }}</p>
    </div>

    <section class="custom-note-section" v-html="deepGet(preorder, 'config.finish_page.content')">
    </section>

    <div class="sticky-bottom footer-action-bar">
      <!-- <router-link
        class="btn w-100 btn-secondary btn-lg"
        :to="{ name: 'LiffPreorderProducts' }"
      >
        我的預購清單
      </router-link> -->
      <MemberButton
        :button-text="deepGet(preorder, 'config.finish_page.button_text')"
        :button-url="deepGet(preorder, 'config.finish_page.button_url')"
        :type="deepGet(preorder, 'config.finish_page.button_action')"
      >
      </MemberButton>
    </div>
  </div>
</template>

<script>
import MemberButton from "@/components/Page/Liff/Shared/MemberButton.vue";
import themeColor from "@/mixins/liff/themeColor";
import deepGet from "lodash/get";
import { mapState } from "vuex";

export default {
  components: {
    MemberButton,
  },
  mixins: [themeColor],
  computed: {
    ...mapState('liffPreorder', { preorder: 'configuration' }),
  },
  methods: {
    deepGet,
  },
};
</script>

<style lang="scss" scoped>
@import "../../../../node_modules/font-awesome/css/font-awesome.min.css";

.one-line-note {
  font-size: 0.875rem;
  color: #888;
}

.custom-note-section {
  margin: 16px;
  padding: 16px;
  background-color: #f8f8f8;
}

.footer-action-bar {
  padding: 8px;
  background-color: white;
  box-shadow: 0px -2px 4px 0px #0000000d;

  .btn {
    border-width: 2px;
    padding: 0.8rem;
    font-weight: 500;
    border-radius: 0.5rem;

    &.btn-primary {
      background-color: var(--s-primary);
      border-color: var(--s-primary);
    }
  }

  .btn + .btn {
    margin-top: 8px;
  }
}

.sticky-bottom {
  position: sticky;
  bottom: 0;
  left: 0;
  right: 0;
}
</style>
